<template>
  <div ref="terminal" id="terminal"></div>
</template>

<script>
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit';
import { commands } from './commands/enabled.ts'
import TerminalController from './terminal/controller'
import 'xterm/css/xterm.css'
import 'xterm/lib/xterm.js'
let terminal = new Terminal({cursorBlink:true});
const fitAddon = new FitAddon();
terminal.loadAddon(fitAddon);
export default {
  name: 'App',
  components: {
    
  },
  data: function(){
    return {
      controller: null
    }
  },
  async mounted() {
    terminal.open(this.$refs["terminal"], false);
    const res = await fetch("/downloads/fs.json")
    const fs = await res.json()
    this.controller = new TerminalController(terminal, commands, fs, "(base) admin@QNAS3012 -> ")
    fitAddon.fit();
    let bannerCmd = "banner";
    console.log(terminal.cols)
    if (terminal.cols < 72){
     
      bannerCmd += " small";
    }

    
    
    
    setTimeout(async () => {
      await this.controller.processLine(bannerCmd)      
    }, 1000)
    
    
  },
  methods:{
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

#terminal {
  height: calc(100vh - 2rem);
}

body{
  margin: 0;
  background: rgb(0, 0, 0);
  padding: 1rem;
  height: 100%;
}
</style>
