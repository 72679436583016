import TerminalController from "@/terminal/controller";
import { Terminal } from "xterm";




export default abstract class Command{
    term: TerminalController
    _input_callback: CallableFunction = function(){}
    _censor_output = false
    constructor(term: TerminalController){
        this.term = term
    }

    async write(x: string | Uint8Array, duration=0, sound=false){
        if(duration <= 0){
            await this.term.write(x)
        }else{
            const len = x.length;
            const waitDuration = duration/len
            console.log("wD:", waitDuration) 
            const a = new Audio("/text_scroll.mp3")
            
            if(sound){
                a.play()
            }
            for(let i = 0; i < len; i++){
                await this.term.write(x[i].toString())
                if(x[i].toString() !== " ")
                    await this.wait(waitDuration)
            }
            if(sound){
                a.pause()
            }
           
        }
        
    }

    sendData(data: string | Uint8Array){
        console.log("send data")
        this._input_callback(data)
    }

    async wait(ms: number){
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    getInput(){
        return new Promise(res => {
            console.log("Install handler")
            this._input_callback = (x: string) => {console.log("input callback"); res(x)};
        })
    }


    
    abstract execute(args: Array<string>): Promise<string | void | Uint8Array>;
}