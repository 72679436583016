import Command from "./commands";

export class DownloadCommand extends Command{
    async execute(args: string[]): Promise<string | void | Uint8Array> {
        const filename = args[0];
        if (!filename){
            await this.write("Do you know what you're trying to download?", 700, true);
            await this.wait(2000)
            await this.write("\r\nMaybe you should specify that. \r\n", 600, true);
            return
        }
        const dlButton = document.createElement("a")
        const path = "/downloads/" + filename
        const res = await fetch(path)
        if (res.status == 200){
            await this.write("Oh, yes. ", 300, true)
            dlButton.href = path
            dlButton.download = filename
            
            document.body.appendChild(dlButton)
            dlButton.click()
            await this.wait(750)
            await this.write("Here you go.\r\n", 500, true)
        }else{
            await this.write("Couldn't find that one. ", 500, true)
            await this.wait(1000)
            await this.write(`¯\\_(ツ)_/¯\r\n`, 200, true)
        }
        
        
        
        // document.body.removeChild(dlButton)

    }
}