import Command from './commands'
import { commands } from './enabled'

class HelpCommand extends Command{
    async execute(): Promise<string | Uint8Array | void> {
        
        await this.write("Please contact the administrator for help.", 1000, true)
        return "\r\n"
    }
}

class MOTDCommand extends Command{
    async execute(args: string[]): Promise<string | void | Uint8Array> {
        await this.write(`
Welcome to xOS 0.1.29 LTS (GNU/Linux 5.4.1-systemX arm64)

* Documentation:  https://xsus.org

    System information as of ${new Date().toUTCString()}

    System load:                      0.3
    Usage of /:                       15.7% of 195.86PB
    Memory usage:                     12%
    Swap usage:                       4%
    Processes:                        ${(11950 + (Math.random() * 130)).toFixed(0)}
    Users logged in:                  ${(new Date().getHours()/2).toFixed(0)}
    IPv4 address for br-451b9bd9d0b6: 172.18.0.1
    IPv4 address for docker0:         172.17.0.1
    IPv4 address for en25Gs0:         10.0.0.68

    => There are 216 zombie processes.

71 updates can be applied to the subsystem.
1 of these updates is a non-standard security update.


*** System restart required ***
Last login: ${new Date().toUTCString()} from 10.0.2.215
        `.split("\n").join("\r\n"), 3000)
        await this.write("\r\n")
    }
}

class ListCommand extends Command{
    async execute(args: string[]): Promise<string | Uint8Array | void> {
        
        
        let dir = this.term.fs;
        if (args[0]){
            console.log(dir)
            const childNames = dir.children.map(i=>i.name)
            console.log(childNames)
            dir = dir.children[args[0]]
        }
        return dir.children.map(i=>i.name).join("\r\n") + "\r\n"
        
    }
}

class ClearCommand extends Command{
    async execute(): Promise<string | Uint8Array | void> {
        this.term.clear();
        await this.term.write('\r\n')
        this.term.clear()

        return ""
        
    }
}

class EchoCommand extends Command{
    async execute(args: string[]): Promise<string | void> {
        let sound = false
        if(args.includes("-s")){
            args = args.filter(s => !s.includes("-s"))
            sound = true
        }
        const text = args.join(" ")
        await this.write(text, 20 * text.length, sound)
        return "\r\n"
    }
}

class EvalCommand extends Command{
    async execute(args: string[]): Promise<string | void> {
        const res = eval(args.join(" "))
        return res.toString() + "\r\n"
    }
}

class PwdCommand extends Command{
    async execute(): Promise<string | Uint8Array | void> {
        return this.term.fs.name + "\r\n";
    }
}

class CdCommand extends Command{
    async execute(): Promise<string | Uint8Array | void> {
        return "[31m[1mChanging directories has been disabled.[0m\r\n";
    }
}

class BannerCommand extends Command{
    async execute(args: string[]): Promise<string | Uint8Array | void> {
        if(this.term._term.cols >= 72){
            // for(let i = 0; i<1;i++){
            //     await this.write(".....", Math.random() * 2000, false)
            // }
            
           
            await this.write("\b".repeat(30));
            const res = await fetch("/banner/iso-os.bin");
            if(res.body){
                const { value } = await res.body.getReader().read();
                await this.term.write(value || "")
            }
           
        }else if(this.term._term.cols >= 32){
            const res = await fetch("/banner/banner.s.bin");
            if(res.body){
                const { value } = await res.body.getReader().read();
                await this.term.write(value || "")
            }
          
        }else{
            await this.term.write("Welcome!")
        }
        
        await this.wait(1000)
        return "\r\n"
        
    }
}

class LoginCommand extends Command{
    async execute(args: Array<string>): Promise<string | Uint8Array | void> {
        let user = null;
        if (args.length > 0){
            user = args[0]
        }else{
            await this.write("Login as: ")   
            user = await this.getInput()
        }
        await this.write("Password: ")
        this._censor_output = true
        console.log('wait for pass')
        const password = await this.getInput()
        console.log('got pass')
        this._censor_output = false
        console.log("LOGIN: ", user, password)
        let success = false;
        try{
            const res = await fetch("/login")
            const json = await res.json()
            success = json.success;
        }catch(e){
            success = false;
        }
        
        if(success){
            // pass
        }else{
            this.write("Invalid username or password\r\n")
        }
        
    }

}

export {
    ListCommand, ClearCommand, HelpCommand, LoginCommand, BannerCommand, PwdCommand, CdCommand, MOTDCommand, EchoCommand, EvalCommand}