import Command from "./commands";

export default class NeoFetch extends Command{
    async execute(): Promise<string | void | Uint8Array> {
        const res = await fetch("/neofetch.out");
        await this.wait(500)
        if(res.body){
            const { value } = await res.body.getReader().read();
             
            await this.write(value || "")
        }
    }
}