import { ListCommand, HelpCommand, ClearCommand, LoginCommand, BannerCommand, PwdCommand, CdCommand, MOTDCommand, EchoCommand, EvalCommand } from './basic'
import NeofetchCommand from './neofetch'
import { DownloadCommand } from './download';
import Command from './commands';
import ShopCommand from './shop'

export const commands: {[name: string]: typeof Command} = {
    help: HelpCommand,
    clear: ClearCommand,
    neofetch: NeofetchCommand,
    login: LoginCommand,
    banner: BannerCommand,
    pwd: PwdCommand,
    cd: CdCommand,
    motd: MOTDCommand,
    echo: EchoCommand,
    eval: EvalCommand,
    download: DownloadCommand,
    ls: ListCommand,
    // shop: ShopCommand
}

function getCommand(command: string): typeof Command{
    return commands[command]
}

