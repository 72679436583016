
import Command from "@/commands/commands";
import { Terminal } from "xterm";

export default class TerminalController {
    _term: Terminal
    _line: string
    _newline: string
    _prompt: string
    _commands: {[name: string]: { new (term: TerminalController): Command; }}
    _active_command: Command | null = null
    _censor_text: boolean = false
    _history: Array<string> = []
    _history_index: number = -1
    _saved_line: string
    fs: any
    constructor(term: Terminal, commands:{[name: string]: { new (term: TerminalController): Command; }}, fs: any, prompt='> ', newline='\r\n',) {
        this._term = term
        this._line = ""
        this._newline = newline
        this._prompt = prompt
        this._commands = commands
        this.fs = fs;
        term.attachCustomKeyEventHandler(this.onKeyDown.bind(this))
        term.clear();
        term.focus()
    }
    async onKeyDown(e: KeyboardEvent) {
        if (e.type != 'keydown') return;
        if(e.key.length == 1){
            this._line += e.key;
            if(this._active_command?._censor_output){
                this._term.write("*")
            }else{
                this._term.write(e.key)
            }
            
        }else{
            switch(e.key){
                case 'Enter': {
                    this._term.write('\r\n');
                    const inputLine = this._line.trim();
                    if(inputLine.length > 0){
                        this._line = "";
                        await this.processLine(inputLine);
                        this._history.unshift(inputLine)
                    }else{
                        this.prompt()
                    }
                    break;
                }
                case 'Backspace': {
                    if (this._line.length == 0) return;
                    this._line = this._line.slice(0, -1)
                    this._term.write("\b \b");
                    break;
                }
                case 'ArrowUp': {
                    if(this._history_index >= this._history.length - 1) return
                    
                    if(this._history_index === -1){

                        this._saved_line = this._line
                        // console.log(this._history)
                    }
                    this._history_index++;
                    
                    const newline = this._history[this._history_index]
                    this.replaceLine(newline)
                    break
                }

                case 'ArrowDown':{

                    if(this._history_index === -1) return
                    this._history_index--
                    let nl = ""
                    if(this._history_index === -1){
                        nl = this._saved_line
                    }else{
                        nl = this._history[this._history_index]
                    }
                    
                    this.replaceLine(nl)
                    break
                }

                default:
                    
                  
            }
        }
    }

    clear(){
        return this._term.clear()
    }

    async write(x: string | Uint8Array){
        await new Promise(res => {
            this._term.write(x, ()=>{
                res(x.length)
            })
        })
    }

    async replaceLine(x: string){
        const len = this._line.length
        await this.write("\b".repeat(len) + " ".repeat(len) + "\b".repeat(len))
        await this.write(x)
        this._line = x
    }

    async prompt(){
       await this.write(this._prompt)
    }

    async writeln(x: string | Uint8Array) : Promise<void>{
        await this.write(x + this._newline)
    }

    setMode(censorText=true){
        this._censor_text = censorText
    }

    async processLine(line :string): Promise<void>{
        console.log("PL", line)
        if(this._active_command){
            console.log("ac", line)
            return this._active_command.sendData(line)
        }
        try{
            
            const [command, ...args] = line.split(" ")
            const cmdClass = command in this._commands ? this._commands[command] : false
            if (!cmdClass){
                await this.writeln(`Unknown command '${command}'`);
            }else{
                this._active_command = new cmdClass(this)
                const out = await this._active_command.execute(args)
                if(out){
                    await this.write(out)
                }
                this._active_command = null
                
            }
        }catch(e: any){
            console.log(e)
            this._active_command = null
        }finally{
            console.log('unset ac')
            
        }
        this.prompt()
        
        
        
    }



}